import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  width: auto;
`;

const Motivation = (props) => {
  return (
    <Icon
      height="800px"
      viewBox="0 0 64 64"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      // fill="#DF3C44"
    >
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-136.000000, -10.000000)">
          <g transform="translate(136.000000, 2.000000)">
            <g transform="translate(0.000000, 8.000000)">
              <path d="M41.502,8.87372A9.97426,9.97426,0,0,0,40.07013,6.093l-.76825.5636A8.98629,8.98629,0,0,1,40.597,9.17133Z" />
              <path d="M23.38721,9.22156l-.90686-.292A9.98761,9.98761,0,0,0,22,11.99951V12.019l.95239-.01953A9.01972,9.01972,0,0,1,23.38721,9.22156Z" />
              <path d="M24.68787,17.32849A8.98993,8.98993,0,0,1,23.3974,14.811l-.90491.29572a9.92357,9.92357,0,0,0,1.42621,2.78357Z" />
              <path d="M26.66376,4.69238l-.56269-.76819a10.01815,10.01815,0,0,0-2.20513,2.21625l.771.558A9.09893,9.09893,0,0,1,26.66376,4.69238Z" />
              <path d="M31.97394,2.95233,31.97021,2a10.04107,10.04107,0,0,0-3.08685.49567l.29669.90582A9.06354,9.06354,0,0,1,31.97394,2.95233Z" />
              <path d="M42,11.99951v-.03815l-.95239.03815a9.04129,9.04129,0,0,1-.43988,2.79566l.90582.29388A9.9955,9.9955,0,0,0,42,11.99951Z" />
              <path d="M29.21307,20.60962a9.05583,9.05583,0,0,1-2.52185-1.28247l-.559.77a9.92609,9.92609,0,0,0,2.78784,1.41827Z" />
              <path d="M32.00836,21.04767,32.01025,22a10.0183,10.0183,0,0,0,3.08777-.49011l-.2948-.90589A9.06669,9.06669,0,0,1,32.00836,21.04767Z" />
              <path d="M37.851,3.88977A9.95109,9.95109,0,0,0,35.06085,2.478l-.29114.90582a9.0289,9.0289,0,0,1,2.52417,1.27783Z" />
              <path d="M40.0929,17.87445l-.77009-.55987a9.10542,9.10542,0,0,1-2.00054,2.0014l.56085.77A10.04159,10.04159,0,0,0,40.0929,17.87445Z" />
              <polygon points="33.545 9.292 32 6 30.455 9.292 27 9.82 29.5 12.382 28.91 16 32 14.292 35.09 16 34.5 12.382 37 9.82 33.545 9.292" />
              <path d="M49,51l-3-7H42L35,28H29L23,44H19l-.0863.20142L12.42188,34l-.84376.53711,3.76795,5.92108-.65515,1.31036H12v-.7774a2,2,0,1,0-2,0v7.15436L9.28638,51H9L3,62H61L55,51ZM16,51H11.3479l.62231-2.48926A1.00851,1.00851,0,0,0,12,48.26855h1.58594l2.50927,2.50928Zm.94366-2.20184L14.707,46.56152a1.00012,1.00012,0,0,0-.707-.293H12v-3.5h3a.50111.50111,0,0,0,.44727-.27636l.525-1.05,2.46429,3.87244Z" />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
};
export default Motivation;
