export const PRODUCTS = [
  { name: "Laborotary Products", link: "#" },
  { name: "Hospital Products", link: "#" },
  { name: "Microscopes", link: "#" },
  { name: "Vetenary Products", link: "#" },
];
export const RESOURCES = [
  { name: "Sigma Centrifuges", link: "https://www.sigma-zentrifugen.de/" },
  { name: "Euroimmun", link: "https://www.euroimmun.com/" },
  { name: "Medcom", link: "#" },
  { name: "Eldon Biologicals A/S", link: "https://eldoncard.com/" },
  { name: "Euromex", link: "https://www.euromex.com/" },
];
export const COMPANY = [
  { name: "P.O BOX 11038-00100 Nairobi Kenya", link: "#" },
  { name: "Buruburu shopping Centre Ngemwa House 2nd floor  Office No. 23.", link: "#" },
  { name: "Tele FAX: +254 (0)20 206 7969", link: "#" },
  { name: "E-mail: info@hckl.co.ke", link: "#" },
  { name: "Website: www.hckl.co.ke", link: "#" },

];
export const SUPPORT = [
  { name: "Documentation", link: "#" },
  { name: "Tutorials & guides", link: "#" },
  { name: "Webinars", link: "#" },
  { name: "Open-source", link: "#" },
];

export const Icons = [
  { name: "logo-facebook", link: "#" },
  { name: "logo-twitter", link: "#" },
  { name: "logo-github", link: "#" },
  { name: "logo-linkedin", link: "#" },
  { name: "logo-instagram", link: "#" },
];
