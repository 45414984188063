import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  width: auto;
`;

const Maintenance = (props) => {
  return (
    <Icon
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105.01 122.88"
      height="70px"
      {...props}
    >
      <defs></defs>
      <title>maintenance</title>

      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-136.000000, -10.000000)">
          <g transform="translate(136.000000, 2.000000)">
            <g transform="translate(0.000000, 8.000000)">
              <path
                class="cls-1"
                d="M.59,97C-.88,102.74.45,109.21,4,114.77c4.54-23.64,32.37-13.59,18.08,8.11,12.37-1,19.05-10.55,18.79-22.31-.12-5.54,0-8.55.87-11.07a13.16,13.16,0,0,1,1.67-3.21,11.5,11.5,0,0,1-.91-4.42v-.29a11.73,11.73,0,0,1,1.2-5l-7.43-7.23-2.06,2.73a53.12,53.12,0,0,1-6.66,6.63,18.68,18.68,0,0,1-4.59,2.07C13,84.19,3.65,85.11.59,97Zm58.16-21-44-42.84-6-1.06a3.65,3.65,0,0,1-1.9-1l-5.11-5a3.65,3.65,0,0,1-.07-5.15l6.17-6.23a3.64,3.64,0,0,1,5.14,0l4.53,4.49a3.58,3.58,0,0,1,1,1.83l2,7.31L64,70.79l4.43-4.46a.7.7,0,0,1,1,0l2.89,2.86a.7.7,0,0,1,.21.45c.12.89.19,1.61.26,2.22.15,1.49.23,2.24.5,2.5s1,.3,2.45.4h0c.62,0,1.37.1,2.37.2a.67.67,0,0,1,.43.2l22.22,22.4A11.49,11.49,0,0,1,104.44,110a11.87,11.87,0,0,1-2.59,4.42,11,11,0,0,1-4.22,2.85c-3.75,1.39-8.44.66-12.87-3.81L63.53,92a.76.76,0,0,1-.21-.44c-.18-1.21-.29-2.19-.38-3-.19-1.61-.29-2.45-.61-2.76s-1.26-.37-3.14-.43c-.65,0-1.41,0-2.3-.09a.7.7,0,0,1-.46-.2l-2.84-2.81a.71.71,0,0,1,0-1l5.17-5.22ZM70.29,90.64a1.76,1.76,0,0,1,0-2.5,1.78,1.78,0,0,1,2.51,0L92,107.6a1.77,1.77,0,0,1-2.5,2.52L70.29,90.64Zm5.28-5.34a1.77,1.77,0,0,1,2.49-2.52l19.25,19.48a1.77,1.77,0,1,1-2.49,2.52L75.57,85.3ZM67,55.36l5.94-7.77c7.15-9.71,25.79-4,30.36-21.75,1.46-5.7.13-12.16-3.39-17.73C95.37,31.75,67.55,21.7,81.84,0,69.47,1,62.79,10.55,63.05,22.31c.12,5.16,1.57,9.37-3.32,15.91L54,45.83,64.5,56.06a11.47,11.47,0,0,1,2.5-.7Z"
                fill="#0065B8"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
};
export default Maintenance;
