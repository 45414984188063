import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  width: auto;
`;

const Support = (props) => {
  return (
    <Icon
      height="80px"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-136.000000, -10.000000)">
          <g transform="translate(136.000000, 2.000000)">
            <g transform="translate(0.000000, 8.000000)">
              <path
                class="st1"
                d="M131.8,200.9h-19v-38.6c7.5-2.8,12.8-10.1,12.8-18.5c0-11-8.9-19.8-19.8-19.8c-11,0-19.8,8.9-19.8,19.8   c0,8.5,5.3,15.7,12.8,18.5v38.6h-19v99.2h52V200.9z"
              />

              <path
                class="st1"
                d="M49.5,324.1v93.4c0,5.5,4.5,9.9,9.9,9.9h46.4V314.1H59.4C54,314.1,49.5,318.6,49.5,324.1z"
              />

              <path
                class="st1"
                d="M452.6,314.1h-46.4v113.3h46.4c5.5,0,9.9-4.5,9.9-9.9v-93.4C462.5,318.6,458,314.1,452.6,314.1z"
              />

              <path
                class="st0"
                d="M119.8,427.3h272.3V314.1H119.8V427.3z M330.1,349.7c11.6,0,21,9.4,21,21c0,11.6-9.4,21-21,21   c-11.6,0-21-9.4-21-21C309.1,359.1,318.5,349.7,330.1,349.7z M256,349.7c11.6,0,21,9.4,21,21c0,11.6-9.4,21-21,21s-21-9.4-21-21   C235,359.1,244.4,349.7,256,349.7z M181.9,349.7c11.6,0,21,9.4,21,21c0,11.6-9.4,21-21,21c-11.6,0-21-9.4-21-21   C160.9,359.1,170.3,349.7,181.9,349.7z"
              />

              <path
                class="st1"
                d="M432.2,200.9h-19v-38.6c7.5-2.8,12.8-10.1,12.8-18.5c0-11-8.9-19.8-19.8-19.8c-11,0-19.8,8.9-19.8,19.8   c0,8.5,5.3,15.7,12.8,18.5v38.6h-19v99.2h52V200.9z"
              />

              <path
                class="st0"
                d="M153.6,141.1C181,113.7,217.3,98.6,256,98.6s75,15.1,102.4,42.5c1.4,1.4,3.2,2.1,5,2.1c1.8,0,3.6-0.7,4.9-2   c2.7-2.7,2.7-7.2,0-9.9c-30-30-69.9-46.6-112.3-46.6s-82.3,16.5-112.3,46.6c-2.7,2.7-2.7,7.2,0,9.9   C146.4,143.8,150.9,143.8,153.6,141.1z"
              />

              <path
                class="st0"
                d="M337.9,168.6c1.8,0,3.6-0.7,5-2.1c2.7-2.7,2.7-7.2,0-9.9c-23.2-23.2-54.1-36-86.9-36s-63.6,12.8-86.9,36   c-2.7,2.7-2.7,7.2,0,9.9c2.7,2.7,7.2,2.7,9.9,0c20.6-20.6,47.9-31.9,77-31.9s56.4,11.3,77,31.9   C334.3,167.9,336.1,168.6,337.9,168.6z"
              />

              <path
                class="st0"
                d="M312.5,194c1.8,0,3.6-0.7,4.9-2.1c2.7-2.7,2.7-7.2,0-9.9c-16.4-16.4-38.2-25.5-61.4-25.5s-45,9-61.4,25.5   c-2.7,2.7-2.7,7.2,0,9.9c2.7,2.7,7.2,2.7,9.9,0c13.8-13.8,32.1-21.4,51.5-21.4s37.7,7.6,51.5,21.4C308.9,193.3,310.7,194,312.5,194   z"
              />

              <path
                class="st0"
                d="M220,207.5c-2.7,2.7-2.7,7.2,0,9.9c2.7,2.7,7.2,2.7,9.9,0c7-7,16.2-10.8,26.1-10.8s19.1,3.8,26.1,10.8   c1.4,1.4,3.2,2.1,5,2.1c1.8,0,3.6-0.7,4.9-2c2.7-2.7,2.7-7.2,0-9.9c-9.6-9.6-22.4-14.9-36-14.9S229.6,197.9,220,207.5z"
              />
            </g>
          </g>
        </g>
      </g>
    </Icon>
  );
};
export default Support;
