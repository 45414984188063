import React, { useEffect, useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { motion } from "framer-motion";
import { useStateValue } from "../../context/StateProvider";
import { actionType } from "../../context/reducer";
import { MdDelete } from "react-icons/md";
import notfound1 from "../../img/notfound1.jpeg"

const CartItem = ({ item, setFlag, flag }) => {
  const [{ cartItems }, dispatch] = useStateValue();
  const [qty, setQty] = useState(item.qty);

  const cartDispatch = () => {
    dispatch({
      type: actionType.SET_CARTITEMS,
      cartItems: cartItems,
    });
  };

  const updateQty = (action, id) => {
    const updatedCartItems = cartItems.map((item) => {
      if (item.id === id) {
        if (action === "add") {
          item.qty += 1;
        } else if (action === "remove") {
          item.qty -= 1;
        }
      }
      return item;
    });

    dispatch({
      type: actionType.SET_CARTITEMS,
      cartItems: updatedCartItems,
    });
    cartDispatch();
  };

  return (
    <div className="w-full p-1 px-2 pb-6 rounded-lg flex items-center gap-2 ">
      <div className="w-full p-1 px-2  rounded-lg flex items-center gap-2  ">
        {/* <img
          src={item?.imageURL}
          className="w-20 h-20 max-w-[60px] rounded-full object-contain"
          alt={notfound}
        /> */}
        <motion.img
          src={item?.imageURL || notfound1}
          className="w-20 h-20 max-w-[60px] max-h-[85.px] rounded-full object-contain"
          alt="NotFound"
          whileHover={{ scale: 2 }}
        />{" "}
        {/* name section */}
        <div className="flex flex-col gap-2">
          <p className="text-base text-gray-50">{item.title}</p>
          <p className="text-base text-gray-50">{item.lgClass}</p>
          <p className="text-base text-gray-50">{item.Format}</p>
          <p className="text-sm block text-gray-300 font-semibold">
            {/* Ksh {parseFloat(item?.price) * qty} */}
            Ksh {item?.price}
          </p>
        </div>
        {/* <div className="w-full flex flex-col items-center justify-between py-4 px-6 rounded-lg my-3 bg-white">
        <p className="text-sm font-semibold text-blue-600">
          Method: {item.Method}
        </p>
        <p className="text-sm font-semibold text-blue-600">
          Parameter: {item.Parameter}
        </p>
        <p className="text-sm font-semibold text-blue-600">
          Substrate: {item.Substrate}
        </p>
        <p className="text-sm font-semibold text-blue-600">
          Species: {item.Species}
        </p>
        <MdDelete
          className="text-red-600 cursor-pointer"
          size="1.5rem"
          // onClick={() => removeFromCart(id)}
        />
      </div> */}
      </div>

      {/* button section */}
      <div className="group flex items-center gap-2 ml-auto cursor-pointer py-7">
        <motion.div
          whileTap={{ scale: 0.75 }}
          onClick={() => updateQty("remove", item?.id)}
        >
          <BiMinus className="text-gray-50 " />
        </motion.div>

        <p className="w-5 h-5 rounded-sm bg-cartBg text-gray-50 flex items-center justify-center">
          {qty}
        </p>

        <motion.div
          whileTap={{ scale: 0.75 }}
          onClick={() => updateQty("add", item?.id)}
        >
          <BiPlus className="text-gray-50 " />
        </motion.div>
      </div>
    </div>
  );
};

export default CartItem;

// import React, { useEffect, useState } from "react";
// import { BiMinus, BiPlus } from "react-icons/bi";
// import { motion } from "framer-motion";
// import { useStateValue } from "../../context/StateProvider";
// import { actionType } from "../../context/reducer";
// import { fetchCart } from "../../utils/fetchLocalStorageData";
// let items = [];

// const CartItem = ({ item, setFlag, flag }) => {
//   const [{ cartItems }, dispatch] = useStateValue();
//   const [qty, setQty] = useState(item.qty);

//   const cartDispatch = () => {
//     localStorage.setItem("cartItems", JSON.stringify(items));
//     dispatch({
//       type: actionType.SET_CARTITEMS,
//       cartItems: items,
//     });
//   };

//   const updateQty = (action, id) => {
//     if (action == "add") {
//       setQty(qty + 1);
//       cartItems.map((item) => {
//         if (item.id === id) {
//           item.qty += 1;
//           setFlag(flag + 1);
//         }
//       });
//       cartDispatch();
//     } else {
//       // initial state value is one so you need to check if 1 then remove it
//       if (qty == 1) {
//         items = cartItems.filter((item) => item.id !== id);
//         setFlag(flag + 1);
//         cartDispatch();
//       } else {
//         setQty(qty - 1);
//         cartItems.map((item) => {
//           if (item.id === id) {
//             item.qty -= 1;
//             setFlag(flag + 1);
//           }
//         });
//         cartDispatch();
//       }
//     }
//   };

//   useEffect(() => {
//     items = cartItems;
//   }, [qty, items]);

//   return (
//     <div className="w-full p-1 px-2 rounded-lg  flex items-center gap-2">
//       <img
//         src={item?.imageURL}
//         className="w-20 h-20 max-w-[60px] rounded-full object-contain"
//         alt=""
//       />

//       {/* name section */}
//       <div className="flex flex-col gap-2">
//         <p className="text-base text-gray-50">{item?.title}</p>
//         <p className="text-sm block text-gray-300 font-semibold">
//           Ksh {parseFloat(item?.price) * qty}
//         </p>
//       </div>

//       {/* button section */}
//       <div className="group flex items-center gap-2 ml-auto cursor-pointer">
//         <motion.div
//           whileTap={{ scale: 0.75 }}
//           onClick={() => updateQty("remove", item?.id)}
//         >
//           <BiMinus className="text-gray-50 " />
//         </motion.div>

//         <p className="w-5 h-5 rounded-sm bg-cartBg text-gray-50 flex items-center justify-center">
//           {qty}
//         </p>

//         <motion.div
//           whileTap={{ scale: 0.75 }}
//           onClick={() => updateQty("add", item?.id)}
//         >
//           <BiPlus className="text-gray-50 " />
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// export default CartItem;
